.zm-container {
  overflow: hidden;
  position: relative;
}

/* Gallery Grid */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin: 0 auto; /* Center grid horizontally */
}

/* Responsive Gallery Images */
.gallery img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

/* Modal */
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  overflow: auto;
}

/* Responsive Modal Image */
.modal img {
  width: 80%;
  max-width: 800px;
  margin: 0 auto; /* Center image horizontally */
  display: block;
  margin-top: 10%;
}
.gallery img:hover {
  scale: 1.2;
  cursor: pointer;
  background:  linear-gradient(
      180deg,
      rgba(255,255,255,0) 0%,
      rgba(255,165,0,0.46) 217.96%
  );
  box-shadow: 0px 72px 49px -51px rgba(255, 165, 0, 0.21);
}

/* Close Button */
.modal .close {
  color: orange;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) { /* Tablet */
  .gallery {
    gap: 8px; /* Reduce gap between images on smaller screens */
  }
}

@media (max-width: 576px) { /* Mobile */
  .gallery {
    grid-template-columns: 1fr; /* Display images in a single column */
  }
}
