.image-wrapper{
    width:70rem;
    height: 40 rem;
    overflow: hidden;
    border: 8px solid black;
}
.image-wrapper>img{
    width: 100%;
    height:100%;
}
.c-container{
    justify-content: space-between;
}
.c-container>div{
    flex:1;
}
.c-right{
    width:100%;
}
.c-left{
    gap:0.5rem;
}
.contactModes{
    margin-top: 2rem;
    gap:1rem;
}
.contactModes .row{
    gap: 1.5rem;

}
.mode{
    width:16rem;
    padding: 1rem;
    border: 0.8px solid rgba(128,128,128,0.143);
    border-radius: 5px;
    gap: 1rem;
    transition: all 300ms ease-in;
}
.mode .button{
    width: 100%;
    background: rgb(239, 181, 73);
    color:white;
    font-size: 0.9rem;
    font-weight: 600;

}
.mode>:nth-child(1){
    width: 100%;
    gap: 1.6rem;   
}
.mode .detail .primaryText{
    font-size: 1.1rem;
    font-weight: 600;
}
.mode:hover{
    scale: 1.1;
    box-shadow: 0 4px 8px rgba(255, 165, 0, 0.5);  
}
.mode .button:hover{
    background: 0 4px 8px rgba(255, 165, 0, 0.5);  
    color:white;
    scale:0.8;

}
@media (max-width: 768px) {
    .contact-image {
      display: none;
    }
  }
/* Add the following CSS at the end of your existing CSS file */

@media (max-width: 768px) {
    .c-container {
      flex-direction: column;
      align-items: center;
    }
  
    .c-left {
      margin-bottom: 2rem;
      text-align: center;
    }
  
    .c-right {
      width: 100%;
    }
  
    .contactModes {
      margin-top: 2rem;
      gap: 1rem;
    }
  
    .contactModes .row {
      gap: 1.5rem;
    }
  
    .mode {
      width: 100%;
    }
  }
  
  
  
