.pdfs {
    margin-top: 20px; /* Adjust the margin as needed */
    display: flex;
    justify-content: space-around; /* Adjust the justification as needed */
    flex-wrap: wrap; /* Wrap items onto the next line if they don't fit */
    align-items: center;
  }
  
  .pdf-item {
    background-color: #f0f0f0; /* Adjust the background color as needed */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle box shadow */
    margin: 10px; /* Adjust the horizontal margin as needed */
  }
  
  .pdf-item a {
    text-decoration: none;
    color: #333; /* Adjust the text color as needed */
  }
  
  .pdf-item:hover {
    background-color: #e0e0e0; /* Adjust the hover background color as needed */
  }

  
  