.c-container {
  justify-content: space-around;
  gap: 0.5rem;
  background-color: black;
}

.sponsor-logos {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  animation: marquee 20s linear infinite forwards;
}

@keyframes marquee {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%); /* Adjust to the width of your logos */
  }
}

.sponsor-logos > img {
  max-width: 100px; /* Set the maximum width */
  max-height: 100px; /* Set the maximum height */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
}

.c-container > h1 {
  color: #cccccc;
  font-style: italic;
}

@media (min-width: 768px) {
  .sponsor-logos {
      gap: 1rem;
  }
}

@media (max-width: 767px) {
  .sponsor-logos {
      gap: 0.75rem;
  }
}

@media (max-width: 576px) {
  .sponsor-logos {
      gap: 0.5rem;
  }
  .sponsor-logos > img {
      width: 45%;
  }
}
