.App{
    position: relative;
    overflow-x: clip;
    background-color: black;
}
.App>:nth-child(1){
    background: black;
    position:  relative;
}

